import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const NoFurtherChangesCanBeMade = styled(RichTextWithModal)`
  ${fonts.paragraphSmall};
  margin-top: ${spacing(4)};
  margin-bottom: 0;
`;
