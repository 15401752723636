import Accordion from '@rsa-digital/evo-shared-components/components/Accordion';
import { SecondaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { SimpleDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import Feature from '@rsa-digital/evo-shared-components/components/Feature';
import { SuccessPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import AccountPagePolicyCard from 'components/PolicyCard/AccountPagePolicyCard';
import TieredAccountPagePolicyCard from 'components/PolicyCard/TieredAccountPagePolicyCard';

export const SectionHeading = styled.h2`
  ${fonts.headingMedium};
  margin-top: 0;
  margin-bottom: ${spacing(3)};
  padding: 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
  `}
`;

const sharedPolicyCardStyling = css`
  :not(:last-child) {
    margin-bottom: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(2)};
  `}
  }

  ${SectionHeading} + & {
    margin-top: ${spacing(3)};

    ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(5)};
  `}
  }
`;

export const StyledPolicyCard = styled(AccountPagePolicyCard)`
  ${sharedPolicyCardStyling}
`;

export const StyledTieredPolicyCard = styled(TieredAccountPagePolicyCard)`
  ${sharedPolicyCardStyling}
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    align-items: center;
  `}
`;

export const StyledSecondaryCta = styled(SecondaryCta)`
  :only-child {
    margin-top: 0;
  }
  margin-top: ${spacing(4)};
`;

export const SimpleDividerWithMargin = styled(SimpleDivider)`
  margin: ${spacing(4)} 0;
`;

export const StyledFeature = styled(Feature)`
  && {
    margin-bottom: 0;
  }
`;

export const ParagraphLarge = styled.p`
  margin: 0;
  ${fonts.paragraphLarge}
`;

export const StyledSuccessPanel = styled(SuccessPanel)`
  width: auto;
  display: inline-block;
  ${fonts.paragraph}
  margin-top: ${spacing(-4)};
  margin-bottom: ${spacing(4)};
`;

export const StyledAccordion = styled(Accordion)`
  && {
    margin-top: ${spacing(-4)};
  }
`;
