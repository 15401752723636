import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { trackButtonClick, trackModalOpen } from 'helpers/eventTracking';
import {
  canPolicyBeRenewed,
  getTierLogoForPolicy,
  isPolicyReadonly,
} from 'helpers/policyHelpers';
import { getPolicyCardNoChangesCanBeMadeContent } from 'helpers/readonlyPolicyHelpers';
import { accountRoutes } from 'helpers/routingHelper';
import { MtaStatus } from 'state/mta/mtaQuote';
import { Policy, RenewalStatus } from 'state/policy/policy';
import { CtaWrapper, NoFurtherChangesCanBeMade } from './styles';
import {
  ContinueWithMtaChange,
  ContinueWithRenewalChange,
} from '../ContinueWithChangeSection';
import PolicyCardPanel from '../Panel';
import PolicyCardWrapper from '../PolicyCardWrapper';
import RenewNowCta from '../RenewNowCta';
import {
  ColumnFlexWrapper,
  Divider,
  LogoImage,
  RowFlexWrapper,
  RowFlexWrapperWithMobileReverse,
  StyledPrimaryCta,
  StyledRichText,
  StyledSecondaryCta,
  SummaryAndRegistrationWithMobileBorder,
} from '../styles';
import { CsAccountTieredLogo } from '../types';
import usePolicyCardData from '../usePolicyCardData';

type AccountPagePolicyCardProps = {
  policy: Policy;
  onCancelMta: () => void;
} & ComponentProps;

export const query = graphql`
  query {
    csTieredMyAccount {
      tier_logos {
        essentials_logo {
          ...CsAsset
        }
        standard_logo {
          ...CsAsset
        }
        extra_logo {
          ...CsAsset
        }
      }
    }
  }
`;

const TieredAccountPagePolicyCard: React.FC<AccountPagePolicyCardProps> = ({
                                                                             policy,
                                                                             onCancelMta,
                                                                             ...props
                                                                           }) => {
  const logoContent = useStaticQuery<CsAccountTieredLogo>(query);
  const { content } = usePolicyCardData(policy);

  const mtaStatus = policy.mtaSummary?.mtaStatus;
  const renewalStatus = policy.renewalSummary?.renewalStatus;

  const policyCanBeRenewed = canPolicyBeRenewed(policy);
  const policyIsReadonly = isPolicyReadonly(policy);
  const noFurtherChangesText = getPolicyCardNoChangesCanBeMadeContent(policy, content);

  const viewPolicyCtaDisplayText = policyIsReadonly
    ? content.csMyAccount.my_policies.view_policy_cta_text
    : content.csMyAccount.my_policies.view_or_edit_policy_cta_text;

  const viewPolicyCtaScreenreaderText = policyIsReadonly
    ? content.csMyAccount.my_policies.view_policy_cta_screenreader_text
    : content.csMyAccount.my_policies.view_or_edit_policy_cta_screenreader_text;

  const policyInfoRichText = policyCanBeRenewed
    ? content.csMyAccount.my_policies.current_policy_details_text
    : content.csMyAccount.my_policies.policy_details_text;

  const ViewPolicyCta =
    policyCanBeRenewed ||
    policy.renewalSummary?.renewalStatus === RenewalStatus.InProgress
      ? StyledSecondaryCta
      : StyledPrimaryCta;

  const logo = getTierLogoForPolicy(policy, logoContent);

  return (
    <PolicyCardWrapper policy={policy} onCancelMta={onCancelMta} {...props}>
      {(requestHandler, toggleCancelMtaModal) => (
        <GridItem desktop={8} tabletLandscape={8}>
          <PolicyCardPanel policy={policy} content={content}>
            <div>
              <RowFlexWrapperWithMobileReverse>
                <SummaryAndRegistrationWithMobileBorder policy={policy} headingAs="div" />
                {logo && <LogoImage image={logo} />}
              </RowFlexWrapperWithMobileReverse>
              {renewalStatus === RenewalStatus.InProgress && (
                <ContinueWithRenewalChange
                  content={content}
                  policy={policy}
                  divider="bottom"
                  ctaDisabled={policy.isInArrears}
                />
              )}
              {policyCanBeRenewed && (
                <>
                  <RowFlexWrapper>
                    <ColumnFlexWrapper>
                      <StyledRichText
                        html={content.csMyAccount.my_policies.policy_renewal_details_text}
                      />
                    </ColumnFlexWrapper>
                    <RenewNowCta
                      policy={policy}
                      content={content.csMyAccount.my_policies.renew_now}
                      requestHandler={requestHandler}
                    />
                  </RowFlexWrapper>
                  <Divider />
                </>
              )}
              <RowFlexWrapper>
                <ColumnFlexWrapper>
                  <StyledRichText html={policyInfoRichText} />
                </ColumnFlexWrapper>
                <CtaWrapper>
                  <ViewPolicyCta
                    cta={{
                      onClick: () => {
                        trackButtonClick('viewPolicyCta', viewPolicyCtaDisplayText);
                        navigate(accountRoutes.policy(policy.policyNumber));
                      },
                      displayText: viewPolicyCtaDisplayText,
                      screenReaderText: viewPolicyCtaScreenreaderText,
                    }}
                    id={`view-policy-${policy.policyNumber}`}
                  />
                </CtaWrapper>
              </RowFlexWrapper>
            </div>
            {mtaStatus === MtaStatus.InProgress && (
              <ContinueWithMtaChange
                content={content}
                policy={policy}
                onCancelMta={() => {
                  toggleCancelMtaModal();
                  trackModalOpen('cancelMtaModal');
                }}
                divider="top"
                ctaDisabled={policy.isInArrears}
              />
            )}
            {policyIsReadonly && (
              <NoFurtherChangesCanBeMade html={noFurtherChangesText} />
            )}
          </PolicyCardPanel>
        </GridItem>
      )}
    </PolicyCardWrapper>
  );
};

export default TieredAccountPagePolicyCard;
