import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import InactivePolicyCard from 'components/PolicyCard/InactivePolicyCard';
import TieredInactivePolicyCard from 'components/PolicyCard/TieredInactivePolicyCard';

const commonInactivePolicyStyles = css`
  margin-top: ${spacing(3)};
`;

export const StyledPolicyCard = styled(InactivePolicyCard)`
  ${commonInactivePolicyStyles}
`;

export const StyledTieredInactivePolicyCard = styled(TieredInactivePolicyCard)`
  ${commonInactivePolicyStyles}
`;
