import { MyAccountResult } from 'api/account/myAccountResult';
import { getAccountName } from './policyHelpers';
import replaceCsPlaceholders, { CsPlaceholders } from './replaceCsPlaceholders';

const csAccountPlaceholders: CsPlaceholders<MyAccountResult> = {
  name: (accountData: MyAccountResult) => getAccountName(accountData),
};

const replaceAccountPlaceholders = <T>(csContent: T, accountData: MyAccountResult): T =>
  replaceCsPlaceholders(csContent, accountData, csAccountPlaceholders);

export default replaceAccountPlaceholders;
