import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { trackButtonClick } from 'helpers/eventTracking';
import { getTierLogoForPolicy } from 'helpers/policyHelpers';
import { accountRoutes } from 'helpers/routingHelper';
import { Policy } from 'state/policy/policy';
import {
  ColumnFlexWrapper,
  CtaWrapper,
  InactiveLogoImage,
  InactiveStyledRichText,
  InactiveSummaryAndRegistrationWithMobileBorder,
  PolicyPageLayoutWrapper,
  RowFlexWrapper,
  StyledPolicyCardPanel,
} from './styles';
import { query } from '../TieredAccountPagePolicyCard';
import { RowFlexWrapperWithMobileReverse, StyledPrimaryCta } from '../styles';
import { CsAccountTieredLogo } from '../types';
import usePolicyCardData from '../usePolicyCardData';

export type InactivePolicyCardProps = {
  policy: Policy;
  isPolicyPage?: boolean;
} & ComponentProps;

const TieredInactivePolicyCard: React.FC<InactivePolicyCardProps> = ({
  policy,
  isPolicyPage,
  ...props
}) => {
  const logoContent = useStaticQuery<CsAccountTieredLogo>(query);
  const { content } = usePolicyCardData(policy);

  const logo = getTierLogoForPolicy(policy, logoContent);

  return (
    <Grid {...componentProps(props)}>
      <GridItem desktop={isPolicyPage ? 10 : 8}>
        <StyledPolicyCardPanel policy={policy} content={content}>
          <PolicyPageLayoutWrapper isPolicyPage={isPolicyPage}>
            <RowFlexWrapperWithMobileReverse>
              <InactiveSummaryAndRegistrationWithMobileBorder
                policy={policy}
                headingAs={isPolicyPage ? 'h1' : 'div'}
              />
              {logo && <InactiveLogoImage image={logo} />}
            </RowFlexWrapperWithMobileReverse>
            <RowFlexWrapper isPolicyPage={isPolicyPage}>
              <ColumnFlexWrapper isPolicyPage={isPolicyPage}>
                <InactiveStyledRichText
                  html={content.csMyAccount.inactive_policies.policy_details_text}
                />
              </ColumnFlexWrapper>
              {!isPolicyPage && (
                <CtaWrapper>
                  <StyledPrimaryCta
                    cta={{
                      displayText:
                        content.csMyAccount.inactive_policies.view_policy_cta_text,
                      screenReaderText:
                        content.csMyAccount.inactive_policies
                          .view_policy_cta_screenreader_text,
                      onClick: () => {
                        trackButtonClick(
                          'viewInactivePolicyCta',
                          content.csMyAccount.inactive_policies.view_policy_cta_text
                        );
                        navigate(accountRoutes.policy(policy.policyNumber));
                      },
                    }}
                    id={`view-policy-${policy.policyNumber}`}
                  />
                </CtaWrapper>
              )}
            </RowFlexWrapper>
          </PolicyPageLayoutWrapper>
        </StyledPolicyCardPanel>
      </GridItem>
    </Grid>
  );
};

export default TieredInactivePolicyCard;
