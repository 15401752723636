import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { HeroWithBackground } from '@rsa-digital/evo-shared-components/components/Hero';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { MyAccountResult } from 'api/account/myAccountResult';
import accountClient from 'api/accountClient';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import AccountDivider from 'components/MyAccount/AccountDivider';
import InactivePolicyAccordionContent from 'components/MyAccount/InactivePolicyAccordionContent';
import PolicyManagement from 'components/PolicyManagement';
import { csQuoteCard } from 'components/QuoteCard';
import { TierLogos } from 'components/TieredQuoteCard';
import {
  trackAccordionCollapse,
  trackAccordionExpand,
} from 'helpers/eventTracking';
import {
  getAccountName,
  getPolicyTier,
  isPolicyActive,
  isPolicyInactive,
} from 'helpers/policyHelpers';
import replaceAccountPlaceholders from 'helpers/replaceAccountPlaceholders';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import { Policy } from 'state/policy/policy';
import { CsMeta } from 'types/contentStack';
import {
  SectionHeading,
  StyledAccordion,
  StyledPolicyCard,
  StyledSuccessPanel,
  StyledTieredPolicyCard,
} from './styles';

type MyAccount = {
  csMyAccount: {
    meta: CsMeta;
    hero_welcome_text: string;
    hero_welcome_fallback_text: string;
    my_policies: {
      heading: string;
      no_policies_text: string;
    };
    inactive_policies: {
      heading: string;
    };
    my_quotes: {
      heading: string;
      no_quotes_text: string;
      start_new_quote_button_label: string;
      quote_card: csQuoteCard;
    };
    password_change_panel_text: string;
    email_change_panel_text: string;
  };
  csTieredMyAccount: {
    tier_logos: TierLogos;
  };
};

type MyAccountProps = {
  location: Location & {
    state?: {
      successfulPasswordChange: boolean | null;
      successfulEmailChange: boolean | null;
    };
  };
};

const query = graphql`
  query {
    csMyAccount {
      meta {
        meta_title
      }
      hero_welcome_text
      hero_welcome_fallback_text
      my_policies {
        heading
        no_policies_text
      }
      inactive_policies {
        heading
      }
      my_quotes {
        heading
        no_quotes_text
        start_new_quote_button_label
        quote_card {
          top_label
          monthly_amount_label
          annual_amount_label
          quote_price_subheading
          quote_details_text
          view_quote_button_label
        }
      }
      password_change_panel_text
      email_change_panel_text
    }
    csTieredMyAccount {
      tier_logos {
        essentials_logo {
          ...CsAsset
        }
        standard_logo {
          ...CsAsset
        }
        extra_logo {
          ...CsAsset
        }
      }
    }
  }
`;

const Account: React.FC<MyAccountProps> = ({ location }) => {
  const [accountData, setAccountData] = useState<MyAccountResult | undefined>();
  const { isLoading, requestHandler } = useApiRequestHandler();
  const { csMyAccount } = useStaticQuery<MyAccount>(query);

  const removeMtaFromPolicy = (policy: Policy): void =>
    setAccountData((oldAccountData) =>
      oldAccountData
        ? {
            ...oldAccountData,
            policies: oldAccountData.policies.map((accountPolicy) =>
              accountPolicy === policy
                ? { ...policy, mtaSummary: undefined }
                : accountPolicy
            ),
          }
        : undefined
    );

  useEffect(() => {
    if (!accountData) {
      requestHandler(async () => accountClient.myAccount().then(setAccountData));
    }
  }, [accountData, requestHandler]);

  usePageTracking(csMyAccount.meta.meta_title, !!accountData);

  if (isLoading || !accountData) {
    return (
      <Layout meta={csMyAccount.meta} pageType="accountArea" hideAccountMenu>
        <LoadingOverlay loadingMessage="Fetching account data" />
      </Layout>
    );
  }

  const text = replaceAccountPlaceholders(csMyAccount, accountData);

  const displayAccountChangeBanner =
    location.state?.successfulPasswordChange || location.state?.successfulEmailChange;
  const hasAccountName = getAccountName(accountData) !== '';
  return (
    <Layout meta={csMyAccount.meta} pageType="accountArea">
      <HeroWithBackground
        heading={
          hasAccountName ? text.hero_welcome_text : text.hero_welcome_fallback_text
        }
        pageType="secondary"
        useHeadingAsH1
        id="accountHero"
        data-pii-mask={hasAccountName}
      />
      {displayAccountChangeBanner && (
        <StyledSuccessPanel data-cy="change account panel">
          {location.state?.successfulPasswordChange
            ? text.password_change_panel_text
            : text.email_change_panel_text}
        </StyledSuccessPanel>
      )}
      <Grid>
        <GridItem desktop={10} tabletLandscape={10} data-cy="MyPolicies">
          <SectionHeading id="my-policies-section-heading">
            {csMyAccount.my_policies.heading}
          </SectionHeading>
          {accountData.policies.some((policy) => isPolicyActive(policy)) ? (
            accountData.policies
              .filter((policy) => isPolicyActive(policy))
              .map((policy: Policy) =>
                getPolicyTier(policy) ? (
                  <StyledTieredPolicyCard
                    policy={policy}
                    key={policy.policyNumber}
                    onCancelMta={() => removeMtaFromPolicy(policy)}
                  />
                ) : (
                  <StyledPolicyCard
                    policy={policy}
                    key={policy.policyNumber}
                    onCancelMta={() => removeMtaFromPolicy(policy)}
                  />
                )
              )
          ) : (
            <RichText html={csMyAccount.my_policies.no_policies_text} />
          )}
        </GridItem>
        {accountData.policies.some((policy) => isPolicyInactive(policy)) ? (
          <>
            <GridItem
              desktop={10}
              tabletLandscape={10}
              tabletPortrait={6}
              data-cy="InactivePolicies">
              <AccountDivider />
              <StyledAccordion
                initiallyDisplayEntries={false}
                accordionEntries={[
                  {
                    title: csMyAccount.inactive_policies.heading,
                    content: <InactivePolicyAccordionContent accountData={accountData} />,
                    onExpand: trackAccordionExpand(csMyAccount.inactive_policies.heading),
                    onCollapse: trackAccordionCollapse(
                      csMyAccount.inactive_policies.heading
                    ),
                  },
                ]}
              />
            </GridItem>
          </>
        ) : (
          <AccountDivider />
        )}        
        <PolicyManagement />
      </Grid>
    </Layout>
  );
};

export default Account;
