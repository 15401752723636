import { InnerAccordionSection } from '@rsa-digital/evo-shared-components/components/Accordion';
import { MyAccountResult } from 'api/account/myAccountResult';
import React from 'react';
import { formatCarDetails } from 'helpers/formatCarDetails';
import { getPolicyTier, isPolicyInactive } from 'helpers/policyHelpers';
import { StyledPolicyCard, StyledTieredInactivePolicyCard } from './styles';

const InactivePolicyAccordionContent: React.FC<{ accountData: MyAccountResult }> = ({
  accountData,
}) => (
  <>
    {accountData.policies &&
      accountData.policies
        .filter((policy) => isPolicyInactive(policy))
        .map((policy) => (
          <div data-pii-mask>
            <InnerAccordionSection
              title={`${formatCarDetails(policy.vehicle, {
                make: true,
                model: true,
              })}, ${policy.vehicle.registration}`}
              sectionId={`inactivepolicy-section-${policy.policyNumber}`}
              entryId={`inactivepolicy-entry-${policy.policyNumber}`}
              key={policy.policyNumber}
              initiallyDisplayEntry={false}>
              {getPolicyTier(policy) ? (
                <StyledTieredInactivePolicyCard
                  policy={policy}
                  key={policy.policyNumber}
                />
              ) : (
                <StyledPolicyCard policy={policy} key={policy.policyNumber} />
              )}
            </InnerAccordionSection>
          </div>
        ))}
  </>
);

export default InactivePolicyAccordionContent;
